<template>
  <div class="honor">
    <div class="data-area">
      <div class="data-item"
           v-for="(item1,index1) in bigTitle"
           :key="index1">
        <div class="title-btn">
          <div style="color: #156ED0;font-weight: bold;flex: 1;">{{ item1.name }}</div>
          <div>
            <el-button plain size="mini" v-if="btnFlg" @click="editBtn(index1, item1)">编辑</el-button>
          </div>
        </div>
        <div class="tags-area">
          <div v-if="tagsValue[item1.request]">
            <div v-for="(item2, index2) in hasTagsMap[item1.request]" :key="index2" class="item-data">
              <span class="item-data-title">{{ item2.name }}：</span>
              <span class="item-data-tags"
                    v-for="item3 in tagsValue[item1.request][item2.request]"
                    :key="item3.uuid">{{ item3.title }}</span>
            </div>
          </div>
          <div v-else>
            <span style="font-size: 14px; color: #CCCCCC">暂无信息，点击编辑添加</span>
          </div>
        </div>
      </div>
    </div>
    <honor-tags-dialog v-model="openDialog"
                       :bigTitle="bigTitleDialog"
                       :tags-name="tagsNameDialog"
                       :tags-data="tagsValueDialog"
                       :tags-map="tagsMapDialog"
                       @closeDialog="dilog"
                       @saveSuccess="reloadData"></honor-tags-dialog>
  </div>
</template>

<script>
import honorTagsDialog from '../dialogs/honorTagsDialog';
import { request } from '../../../../network';
import { getEntityId } from '@/assets/public/utils/token';
export default {
  name: 'honor',
  data () {
    return {
      openDialog: false,
      bigTitle: [
        { request: 'honoraryTitle', name: '荣誉称号', type: '1' },
        { request: 'businessLicense', name: '经营许可', type: '2' },
        { request: 'internationalCertification', name: '国际认证', type: '3' },
        { request: 'domesticCertification', name: '国内认证', type: '4' },
        { request: 'keepOnRecord', name: '备案', type: '5' }
      ],
      bigTitleDialog: {},
      tagsName: {
        honoraryTitle: {},
        businessLicense: {},
        internationalCertification: {},
        domesticCertification: {},
        keepOnRecord: {}
      },
      tagsNameDialog: {},
      tagsMap: {
        honoraryTitle: [
          { request: 'KeJi', name: '科技' },
          { request: 'GongXi', name: '工信' },
          { request: 'ShangWu', name: '商务' },
          { request: 'ShiJian', name: '市监' },
          { request: 'WenLv', name: '文旅' },
          { request: 'NongYe', name: '农业' },
          { request: 'WeiJian', name: '卫健' },
          { request: 'FaGai', name: '发改' },
          { request: 'JiaoYu', name: '教育' },
          { request: 'ZhiShiChanQuan', name: '知识产权' },
          { request: 'HuanBao', name: '环保' }
        ],
        businessLicense: [
          { request: 'GongXi', name: '工信' },
          { request: 'ShiJian', name: '市监' },
          { request: 'WenLv', name: '文旅' },
          { request: 'HuanBao', name: '环保' },
          { request: 'JiaoTong', name: '交通' },
          { request: 'NongYe', name: '农业' }
        ],
        internationalCertification: [
          { request: 'ISO', name: 'ISO' },
          { request: 'ShiPin', name: '食品' },
          { request: 'YiLiaoQiXie', name: '医疗器械' },
          { request: 'ZhiYao', name: '制药' },
          { request: 'DianZi', name: '电子' }
        ],
        domesticCertification: [
          { request: 'MoRen', name: '默认' }
        ],
        keepOnRecord: [
          { request: 'ShangWu', name: '商务' },
          { request: 'JinRong', name: '金融' },
          { request: 'TongJi', name: '统计' },
          { request: 'GuoTu', name: '国土' }
        ]
      },
      tagsMapDialog: [],
      hasTagsMap: {
        honoraryTitle: [],
        businessLicense: [],
        internationalCertification: [],
        domesticCertification: [],
        keepOnRecord: []
      },
      tagsValue: {
        honoraryTitle: {},
        businessLicense: {},
        internationalCertification: {},
        domesticCertification: {},
        keepOnRecord: {}
      },
      tagsValueDialog: {},
      btnFlg:false
    };
  },
  components: {
    honorTagsDialog
  },
  created () {},
  mounted () {
    this.findHonoraryQualificationValue();
    this.findHonoraryQualificationName();
  },
  methods: {
    editBtn (index, item) {
      this.openDialog = true;
      this.bigTitleDialog = this.bigTitle[index];
      this.tagsMapDialog = this.tagsMap[item.request];
      switch (index) {
        case 0:
          this.tagsNameDialog = this.tagsName.honoraryTitle;
          if (this.tagsValue.honoraryTitle) {
            this.tagsValueDialog = this.tagsValue.honoraryTitle;
          } else {
            this.tagsValueDialog = null;
          }
          break;
        case 1:
          this.tagsNameDialog = this.tagsName.businessLicense;
          if (this.tagsValue.businessLicense) {
            this.tagsValueDialog = this.tagsValue.businessLicense;
          } else {
            this.tagsValueDialog = null;
          }
          break;
        case 2:
          this.tagsNameDialog = this.tagsName.internationalCertification;
          if (this.tagsValue.internationalCertification) {
            this.tagsValueDialog = this.tagsValue.internationalCertification;
          } else {
            this.tagsValueDialog = null;
          }
          break;
        case 3:
          this.tagsNameDialog = this.tagsName.domesticCertification;
          if (this.tagsValue.domesticCertification) {
            this.tagsValueDialog = this.tagsValue.domesticCertification;
          } else {
            this.tagsValueDialog = null;
          }
          break;
        case 4:
          this.tagsNameDialog = this.tagsName.keepOnRecord;
          if (this.tagsValue.keepOnRecord) {
            this.tagsValueDialog = this.tagsValue.keepOnRecord;
          } else {
            this.tagsValueDialog = null;
          }
          break;
      }
    },
    hasTagName (name, keyName) {
      const arr = [];
      for (const key in keyName) {
        arr.push(key);
      }
      const returnArr = this.tagsMap[name].filter(e => {
        return arr.indexOf(e.request) > -1;
      });
      this.hasTagsMap[name] = returnArr;
    },
    dilog () {
      this.tagsValueDialog = null;
    },
    async findHonoraryQualificationName () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findHonoraryQualification'
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsName = res.data;
      this.btnFlg=true
    },
    async findHonoraryQualificationValue () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findHonoraryQualification',
        params: {
          // entityId: getEntityId()
          entityId: entityId
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsValue = res.data;
      for (const key in this.tagsValue) {
        this.hasTagName(key, this.tagsValue[key]);
      }
    },
    reloadData () {
      this.findHonoraryQualificationValue();
      this.findHonoraryQualificationName();
    }
  }
};
</script>

<style scoped>
.data-item {
  padding-bottom: 30px;;
}
.title-btn{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.item-data {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0
}
.item-data-title {
  font-size: 14px;
  margin-top: -3px;
  color: #777777;
}
.item-data-tags {
  padding: 3px 8px;
  margin-right: 12px;
  font-size: 14px;
  background-color: #e3eeff;
  color: #156ED0;
  border-radius: 3px;
  margin-bottom: 3px
}
</style>
