<template>
  <div class="knowledge">
    <el-alert
      title="（以下所填信息，为上一年度数据）"
      type="info"
      show-icon
      :closable="false"
    ></el-alert>

    <div class="content">
      <!-- 专利 -->
      <div class="outside" v-show="showPatent">
        <div class="t_content">
          <div class="title">专利</div>
          <el-button @click="showPatent = !showPatent" size="mini"
            >编辑</el-button
          >
        </div>
        <div class="f_content">
          <div v-for="(item, index) in patentLabel" :key="index">
            <div>{{ item.title }}</div>
            <div>{{ patentValue[index] }}</div>
            <div>件</div>
          </div>
        </div>
      </div>
      <div class="inside" v-show="!showPatent">
        <div class="t_content">
          <div><div class="title">专利</div></div>
          <div>
            <el-button @click="savePatent" size="mini">保存</el-button>
            <el-button @click="showPatent = !showPatent" size="mini"
              >取消</el-button
            >
          </div>
        </div>
        <div class="f_content">
          <div>
            <div
              v-for="(item, index) in patentValue"
              :key="index"
              class="editInput"
              :style="
                index == patentValue.length - 1 ? '' : 'margin-bottom:20px'
              "
            >
              <div style="width: 100px">{{ patentLabel[index].title }}</div>
              <div>
                <el-input
                  v-model="patentValue[index]"
                  oninput="value=value.replace(/^0|[^\d]/g, '')"
                  maxlength="9"
                >
                </el-input>
              </div>
              <div style="margin-left: 20px">件</div>
            </div>
          </div>
          <div style="margin-left: 40px" class="item-checkbox">
            <el-checkbox-group v-model="checkPatent" style="height: 200px">
              <el-checkbox
                v-for="(item, index) in checkListPatent"
                :label="item.id"
                :key="index"
                style="margin-bottom: 5px"
              >
                {{ item.title }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <el-divider></el-divider>

      <!-- 商标 -->
      <div class="outside" v-show="showTrademark">
        <div class="t_content">
          <div class="title">商标</div>
          <el-button @click="showTrademark = !showTrademark" size="mini"
            >编辑</el-button
          >
        </div>
        <div class="f_content">
          <div v-for="(item, index) in trademarkLabel" :key="index">
            <div>{{ item.title }}</div>
            <div>{{ trademarkValue[index] }}</div>
            <div>件</div>
          </div>
        </div>
      </div>
      <div class="inside" v-show="!showTrademark">
        <div class="t_content">
          <div><div class="title">商标</div></div>
          <div>
            <el-button @click="saveTrademark" size="mini">保存</el-button>
            <el-button @click="showTrademark = !showTrademark" size="mini"
              >取消</el-button
            >
          </div>
        </div>
        <div class="f_content">
          <div>
            <div
              div
              v-for="(item, index) in trademarkValue"
              :key="index"
              class="editInput"
              :style="
                index == trademarkValue.length - 1 ? '' : 'margin-bottom:20px'
              "
            >
              <div style="width: 100px">{{ trademarkLabel[index].title }}</div>
              <div>
                <el-input
                  v-model="trademarkValue[index]"
                  oninput="value=value.replace(/^0|[^\d]/g, '')"
                  maxlength="9"
                ></el-input>
              </div>
              <div style="margin-left: 20px">件</div>
            </div>
          </div>
          <div style="margin-left: 40px" class="item-checkbox">
            <el-checkbox-group v-model="checkTrademark" style="height: 100px">
              <el-checkbox
                v-for="(item, index) in checkListTrademark"
                :label="item.id"
                :key="index"
                style="margin-bottom: 5px; margin-top: 0"
                >{{ item.title }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <el-divider></el-divider>

      <!-- 著作权 -->
      <div class="outside" v-show="showCopyright">
        <div class="t_content">
          <div class="title">著作权</div>
          <el-button @click="showCopyright = !showCopyright" size="mini"
            >编辑</el-button
          >
        </div>
        <div class="f_content">
          <div v-for="(item, index) in copyrightLabel" :key="index">
            <div>{{ item.title }}</div>
            <div>{{ copyrightValue[index] }}</div>
            <div>件</div>
          </div>
        </div>
      </div>
      <div class="inside" v-show="!showCopyright">
        <div class="t_content">
          <div><div class="title">著作权</div></div>
          <div>
            <el-button @click="saveCopyright" size="mini">保存</el-button>
            <el-button @click="showCopyright = !showCopyright" size="mini"
              >取消</el-button
            >
          </div>
        </div>
        <div class="f_content">
          <div>
            <div
              div
              v-for="(item, index) in copyrightValue"
              :key="index"
              class="editInput"
              :style="
                index == copyrightValue.length - 1 ? '' : 'margin-bottom:20px'
              "
            >
              <div style="width: 100px">{{ copyrightLabel[index].title }}</div>
              <div>
                <el-input
                  v-model="copyrightValue[index]"
                  oninput="value=value.replace(/^0|[^\d]/g, '')"
                  maxlength="9"
                ></el-input>
              </div>
              <div style="margin-left: 20px">件</div>
            </div>
          </div>
          <div style="margin-left: 40px" class="item-checkbox">
            <el-checkbox-group v-model="checkCopyright" style="height: 100px">
              <el-checkbox
                v-for="(item, index) in checkListCopyright"
                :label="item.id"
                :key="index"
                style="margin-bottom: 5px"
                >{{ item.title }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "../../../../network";
import { getEntityId } from "@/assets/public/utils/token";
export default {
  name: "knowledge",
  data() {
    return {
      patentLabel: [],
      patentValue: [],
      showPatent: true,
      checkPatent: [],
      checkListPatent: [],
      trademarkLabel: [],
      trademarkValue: [],
      showTrademark: true,
      checkTrademark: [],
      checkListTrademark: [],
      copyrightLabel: [],
      copyrightValue: [],
      showCopyright: true,
      checkCopyright: [],
      checkListCopyright: [],
      // arrobj:{
      //   useful:[],
      //   appearance:[],
      //   patent:[],
      //   invent:[]
      // }
      arr: [[], [], [], []],
    };
  },
  mounted() {
    // this.findRelatedLabelsName().then(() => this.findRelatedLabelsNameLabel())
    this.getFindIntellectualPropertyNameLabel();
    this.findIntellectualPropertyName();
    this.findRelatedLabelsName();
    this.findRelatedLabelsNameLabel();
  },
  methods: {
    async getFindIntellectualPropertyNameLabel() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/PersonalCenter/findIntellectualProperty",
      });
      if (res.code !== 200) return this.$message.error("信息获取失败");
      this.patentLabel = res.data.patent;
      this.trademarkLabel = res.data.trademark;
      this.copyrightLabel = res.data.copyright;
    },
    async findIntellectualPropertyName() {
      const entityId = getEntityId();
      const params = {
        entityId: entityId,
      };
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/PersonalCenter/findIntellectualProperty",
        params: params,
      });
      if (res.code !== 200) return this.$message.error("信息获取失败");
      this.patentValue = res.data.patent;
      this.trademarkValue = res.data.trademark;
      this.copyrightValue = res.data.copyright;
      return Promise.resolve();
    },
    async findRelatedLabelsName() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/PersonalCenter/findRelatedLabels",
      });
      if (res.code !== 200) return this.$message.error("信息获取失败");
      res.data.patent.forEach((v) => {
        v.active = false;
      });
      this.checkListPatent = res.data.patent;
      res.data.trademark.forEach((v) => {
        v.active = false;
      });
      this.checkListTrademark = res.data.trademark;
      res.data.copyright.forEach((v) => {
        v.active = false;
      });
      this.checkListCopyright = res.data.copyright;
      let arr = [];
      arr[0] = this.checkListPatent[1];
      arr[1] = this.checkListPatent[0];
      arr[2] = this.checkListPatent[2];
      arr[3] = this.checkListPatent[3];
      arr[4] = this.checkListPatent[4];
      arr[5] = this.checkListPatent[5];
      arr[6] = this.checkListPatent[6];
      arr[7] = this.checkListPatent[7];
      this.checkListPatent = arr;
      return Promise.resolve();
    },
    async findRelatedLabelsNameLabel() {
      const entityId = getEntityId();
      const params = {
        entityId: entityId,
      };
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/PersonalCenter/findRelatedLabels",
        params: params,
      });
      if (res.code !== 200) return this.$message.error("信息获取失败");
      const arr = res.data.exist;
      const a = [];
      const b = [];
      const c = [];
      for (let index = 0; index < arr.length; index++) {
        for (let j = 0; j < this.checkListPatent.length; j++) {
          if (arr[index].id === this.checkListPatent[j].id) {
            this.checkListPatent[j].active = true;
            a.push(this.checkListPatent[j].id);
          }
        }
      }
      for (let index = 0; index < arr.length; index++) {
        for (let j = 0; j < this.checkListTrademark.length; j++) {
          if (arr[index].id === this.checkListTrademark[j].id) {
            this.checkListTrademark[j].active = true;
            b.push(this.checkListTrademark[j].id);
          }
        }
      }
      for (let index = 0; index < arr.length; index++) {
        for (let j = 0; j < this.checkListCopyright.length; j++) {
          if (arr[index].id === this.checkListCopyright[j].id) {
            this.checkListCopyright[j].active = true;
            c.push(this.checkListCopyright[j].id);
          }
        }
      }
      this.checkPatent = a;
      this.checkTrademark = b;
      this.checkCopyright = c;
      return Promise.resolve();
    },
    async save(url, inputStr, checkStr, num) {
      const entityId = getEntityId();
      const { data: res } = await request({
        method: "PUT",
        url: url,
        data: {
          entityId: entityId,
          str: inputStr,
          str1: checkStr,
        },
      });
      if (res.code !== 200) return this.$message.error("编辑失败");
      if (num === 0) {
        this.showPatent = !this.showPatent;
      } else if (num === 1) {
        this.showTrademark = !this.showTrademark;
      } else {
        this.showCopyright = !this.showCopyright;
      }
      this.$message.success("编辑成功");
    },
    // 专利编辑
    savePatent() {
      const inputStr = [];
      const checkListRequest = [];
      this.patentValue.forEach((v, i) => {
        inputStr.push(v);
      });
      for (let i = 0; i < this.checkListPatent.length; i++) {
        if (this.checkPatent.indexOf(this.checkListPatent[i].id) > -1) {
          checkListRequest[i] = true;
        } else {
          checkListRequest[i] = false;
        }
      }
      this.save(
        "/pcp/PersonalCenter/updateIntellectualPatent",
        inputStr,
        checkListRequest,
        0
      );
    },

    // 商标编辑
    saveTrademark() {
      const inputStr = [];
      const checkListRequest = [];
      this.trademarkValue.forEach((v, i) => {
        inputStr.push(v);
      });

      for (let i = 0; i < this.checkListTrademark.length; i++) {
        if (this.checkTrademark.indexOf(this.checkListTrademark[i].id) > -1) {
          checkListRequest[i] = true;
        } else {
          checkListRequest[i] = false;
        }
      }
      this.save(
        "/pcp/PersonalCenter/updateTrademark",
        inputStr,
        checkListRequest,
        1
      );
    },
    // 著作权编辑
    saveCopyright() {
      const inputStr = [];
      const checkListRequest = [];
      this.copyrightValue.forEach((v, i) => {
        inputStr.push(v);
      });

      for (let i = 0; i < this.checkListCopyright.length; i++) {
        if (this.checkCopyright.indexOf(this.checkListCopyright[i].id) > -1) {
          checkListRequest[i] = true;
        } else {
          checkListRequest[i] = false;
        }
      }
      this.save(
        "/pcp/PersonalCenter/updateCopyright",
        inputStr,
        checkListRequest,
        2
      );
    },
  },
};
</script>

<style lang="less" scoped>
.knowledge {
  .content {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999;
    .outside {
      margin-top: 50px;
      height: 100%;
      .t_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .f_content {
        margin-top: 20px;
        > div {
          display: flex;
          > div {
            margin-right: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .inside {
      margin-top: 50px;
      height: 100%;
      .t_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .f_content {
        margin-top: 20px;
        display: flex;
        align-items: center;
        > div:nth-child(1) {
          .editInput {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
/deep/.el-input__inner {
  height: 30px !important;
  line-height: 30px !important;
  padding: 0 10px !important;
  width: 200px !important;
}
.title {
  color: rgb(21, 110, 208);
  font-weight: bold;
  flex: 1 1 0%;
}
.item-checkbox {
  margin-left: 45px;
  font-size: 14px;
  color: #000000;
  // display: flex;
  // flex-direction: column;
}
.el-checkbox {
  margin-right: 40px;
  line-height: 45px;
  // margin-right: 60px;
  // margin-bottom: 25px;
  // width: 35%;
}
// .el-checkbox:nth-child(2n){
//   margin-right: 100px;
// }
</style>
