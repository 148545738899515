<template>
  <div>
    <el-alert title="（以下所填信息，为上一年度数据）" type="info" show-icon :closable="false"></el-alert>
    <div class="activity">
      <div class="operating-activities">
        <!--  经营活动区域-->
        <div class="title-btn">
          <div style="color: #156ED0;font-weight: bold;flex: 1;">经营活动</div>
          <div>
            <el-button v-if="activitiesFlag" plain size="mini" @click="activitiesFlag = false">编辑</el-button>
            <div v-else>
              <el-button plain type="primary" size="mini" @click="saveActivities">保存</el-button>
              <el-button plain size="mini" @click="activtyClose1">取消</el-button>
            </div>
          </div>
        </div>
        <div v-if="activitiesFlag">
<!--          展示数据-->
          <el-row style="width: 100%">
            <el-col v-for="(item1,index1) in tagsName.operatingActivities"
                    :key="item1.id" class="col-item" :span="6">
              <span style="width: 90px">{{ item1.title }}</span>
              <img src="@/assets/pc/images/personal/yes.png" class="img" v-if="tagsValue.internationalTrade[index1] == '1'">
              <img src="@/assets/pc/images/personal/no.png" class="img" v-else>
            </el-col>
          </el-row>
        </div>
        <div v-else>
<!--          编辑数据-->
          <div class="item-edit">
            <div class="item-edit-item"
                 v-for="(item2,index2) in tagsName.operatingActivities"
                 :key="item2.id">
              <span class="item-title">{{ item2.title }}</span>
              <div class="item-radio">
                <el-radio-group v-model="radios[index2]" @change="radioChange">
                  <el-radio label="1">有</el-radio>
                  <el-radio label="0">无</el-radio>
                </el-radio-group>
              </div>
              <div class="item-checkbox">
                <el-checkbox-group v-model="checkList" @change="checkBoxChange">
                  <div v-if="index2 !== 5">
<!--                    第五个标签没有隐藏标签-->
                    <template v-if="index2 === 0">
                      <el-checkbox :label="RelateOperatingActivities[index2].id" :disabled="radios[index2] !== '1'">
                        {{RelateOperatingActivities[index2].title}}</el-checkbox>
                      <el-checkbox :label="RelateOperatingActivities[index2+1].id" :disabled="radios[index2] !== '1'">
                        {{RelateOperatingActivities[index2+1].title}}</el-checkbox>
                    </template>
                    <template v-else-if="index2 === 6">
                      <el-checkbox :label="RelateOperatingActivities[index2*2-2].id" :disabled="radios[index2] !== '1'">
                        {{RelateOperatingActivities[index2*2-2].title}}</el-checkbox>
                      <el-checkbox :label="RelateOperatingActivities[index2*2-1].id" :disabled="radios[index2] !== '1'">
                        {{RelateOperatingActivities[index2*2-1].title}}</el-checkbox>
                    </template>
                    <template v-else>
                      <el-checkbox :label="RelateOperatingActivities[index2*2].id" :disabled="radios[index2] !== '1'">
                        {{ RelateOperatingActivities[index2 * 2].title }}</el-checkbox>
                      <el-checkbox :label="RelateOperatingActivities[index2*2+1].id" :disabled="radios[index2] !== '1'">
                        {{RelateOperatingActivities[index2*2+1].title}}</el-checkbox>
                    </template>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="international-trade">
        <!--  国际贸易区域-->
        <div class="title-btn">
          <div style="color: #156ED0;font-weight: bold;flex: 1;">国际贸易</div>
          <div>
            <el-button v-if="tradeFlag" plain size="mini" @click="tradeFlag = false">编辑</el-button>
            <div v-else>
              <el-button plain type="primary" size="mini" @click="saveTrade">保存</el-button>
              <el-button plain size="mini" @click="activtyClose2">取消</el-button>
            </div>
          </div>
        </div>
        <div class="data-area">
<!--          数据区域-->
          <div class="data-item" v-for="(item1,index1) in tagsName.internationalTrade" :key="item1.id">
            <span style="width: 90px">{{ item1.title }}</span>
            <div class="data-item-show" v-if="tradeFlag">{{ tagsValue.operatingActivities[index1] }} 万美元</div>
            <div class="data-item-edit" v-else><el-input v-model="tradeData[index1]" style="width: auto;" maxlength="9" oninput="value=value.replace(/^0|[^\d]/g, '')" />&nbsp;万美元</div>
          </div>
        </div>
      </div>
      <div class="service-outsourcing">
        <!--  服务外包区域-->
        <div class="title-btn">
          <div style="color: #156ED0;font-weight: bold;flex: 1;">服务外包</div>
          <div>
            <el-button v-if="serviceFlag" plain size="mini" @click="serviceFlag = false">编辑</el-button>
            <div v-else>
              <el-button plain type="primary" size="mini" @click="saveService">保存</el-button>
              <el-button plain size="mini" @click="activtyClose3">取消</el-button>
            </div>
          </div>
        </div>
        <div class="data-area">
          <!--数据区域-->
          <div class="data-item" v-for="(item1,index1) in tagsName.serviceOutsourcing" :key="item1.id">
            <span style="width: 90px">{{ item1.title }}</span>
            <div class="data-item-show" v-if="serviceFlag">{{ tagsValue.serviceOutsourcing[index1] }} 万美元</div>
            <div class="data-item-edit" v-else><el-input v-model="serviceData[index1]" style="width: auto;" maxlength="9" oninput="value=value.replace(/^0|[^\d]/g, '')" />&nbsp;万美元</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../network';
import { getEntityId } from '@/assets/public/utils/token';
export default {
  data () {
    return {
      radios: [ '0', '0', '0', '0', '0', '0', '0' ],
      checkList: [],
      checkListDisable: [],
      activitiesFlag: true,
      tradeFlag: true,
      tradeData: [ '', '' ],
      serviceFlag: true,
      serviceData: [ '', '' ],
      tagsName: {
        internationalTrade: [],
        serviceOutsourcing: [],
        operatingActivities: []
      },
      tagsValue: {
        internationalTrade: [],
        serviceOutsourcing: [],
        operatingActivities: []
      },
      tagsRelatedValue: {},
      tagsRelatedName: {},
      RelateOperatingActivities: [
        {
          id: 0,
          input_type: 1,
          title: ''
        }
      ]
    };
  },
  created () {},
  mounted () {
    this.findInternationalTradeName().then(() => this.findInternationalTradeValue());
    this.findRelatedLabelsName().then(() => this.findRelatedLabelsValue());
  },
  computed: {
    getSpanNum () {
      return this.tagsName.operatingActivities.length;
    }
  },
  watch: {

  },
  methods: {
    async findInternationalTradeName () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findInternationalTrade'
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsName = res.data;
      return Promise.resolve();
    },
    async findInternationalTradeValue () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findInternationalTrade',
        params: {
          // entityId: getEntityId()
          entityId: entityId
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsValue = res.data;
      this.radios = this.tagsValue.internationalTrade;
      this.tradeData = this.tagsValue.operatingActivities;
      this.serviceData = this.tagsValue.serviceOutsourcing;

      return Promise.resolve();
    },
    async findRelatedLabelsName () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findRelatedLabels'
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsRelatedName = res.data;
      this.RelateOperatingActivities = this.tagsRelatedName.operatingActivities;
      return Promise.resolve();
    },
    async findRelatedLabelsValue () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findRelatedLabels',
        params: {
          // entityId: getEntityId()
          entityId: entityId
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsRelatedValue = res.data;
      this.checkList = this.tagsRelatedValue.exist.filter(e => {
        let flag = false;
        for (let i = 0; i < this.RelateOperatingActivities.length; i++) {
          if (this.RelateOperatingActivities[i].id === e.id) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        return flag;
      }).map(e => e.id);
      let arr = [];
        arr[0] = this.RelateOperatingActivities[1];
        arr[1] = this.RelateOperatingActivities[0];
        arr[2] = this.RelateOperatingActivities[3];
        arr[3] = this.RelateOperatingActivities[2];
        arr[4] = this.RelateOperatingActivities[5];
        arr[5] = this.RelateOperatingActivities[4];
        arr[6] = this.RelateOperatingActivities[7];
        arr[7] = this.RelateOperatingActivities[6];
        arr[8] = this.RelateOperatingActivities[9];
        arr[9] = this.RelateOperatingActivities[8];
        arr[10] = this.RelateOperatingActivities[10];
        arr[11] = this.RelateOperatingActivities[11];
      this.RelateOperatingActivities = arr;
    },
    async saveActivities () {
      const checkListRequest = [];

      for (let i = 0; i < this.RelateOperatingActivities.length; i++) {
        if (this.checkList.indexOf(this.RelateOperatingActivities[i].id) > -1) {
          checkListRequest[i] = true;
        } else {
          checkListRequest[i] = false;
        }
      }
      const radioRequest = this.radios.map(item => {
        return parseInt(item);
      });
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateOperatingActivities',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          str: radioRequest,
          str1: checkListRequest
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.findInternationalTradeName().then(() => this.findInternationalTradeValue());
      this.findRelatedLabelsName().then(() => this.findRelatedLabelsValue());
      this.activitiesFlag = true;
    },
    checkBoxChange () {
    },
    radioChange () {
      if (this.radios) {
        this.radios.forEach((item, index) => {
          if (item === '0') {
            if (index !== 5) {
              if (index === 0) {
                this.checkList = this.checkList.filter(item2 => {
                  return ((item2 !== this.RelateOperatingActivities[index].id) && (item2 !== this.RelateOperatingActivities[index + 1].id));
                });
              } else if (index === 6) {
                this.checkList = this.checkList.filter(item3 => {
                  return ((item3 !== this.RelateOperatingActivities[index * 2 - 2].id) && (item3 !== this.RelateOperatingActivities[index * 2 - 1].id));
                });
              } else {
                this.checkList = this.checkList.filter(item4 => {
                  return ((item4 !== this.RelateOperatingActivities[index * 2].id) && (item4 !== this.RelateOperatingActivities[index * 2 + 1].id));
                });
              }
            }
          }
        });
      } else {
        this.$message.error('该公司没用标签，不可选择');
        this.activitiesFlag = true;
      }
    },
    activtyClose1 () {
      this.findInternationalTradeValue().then(() => { this.activitiesFlag = true; });
      this.findRelatedLabelsName().then(() => this.findRelatedLabelsValue());
    },
    activtyClose2 () {
      this.findInternationalTradeValue().then(() => { this.tradeFlag = true; });
      this.findRelatedLabelsName().then(() => this.findRelatedLabelsValue());
    },
    activtyClose3 () {
      this.findInternationalTradeValue().then(() => { this.serviceFlag = true; });
      this.findRelatedLabelsName().then(() => this.findRelatedLabelsValue());
    },
    async saveTrade () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateInternationalTrade',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          exportVolume: this.tradeData[0],
          importVolume: this.tradeData[1]
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.findInternationalTradeValue().then(() => { this.tradeFlag = true; });
    },
    async saveService () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateServiceOutsource',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          onShore: this.serviceData[0],
          offshore: this.serviceData[1]
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.findInternationalTradeValue().then(() => { this.serviceFlag = true; });
    }
    // handleInput(str){
    //   let reg = /[^\d]/   //校验非数字类
    //   if (reg.test(str)) {
    //     this.$message({
    //       showClose: true,
    //       message: '只能输入数字',
    //       type: 'error'
    //     });
    //     this.list[index].children[i].num = 0
    //   }else if (str.length > 0 && str.slice(0,1) == 0) {
    //     this.list[index].children[i].num = str.slice(1,str.length)
    //   }else if (str == '') {
    //     this.list[index].children[i].num = 0
    //   }
    // }
  }
};
</script>

<style lang="less" scoped>
  .operating-activities,.international-trade,.service-outsourcing {
    padding-bottom: 30px;
    border-bottom: 1px solid #EBEBEB;
    margin-bottom: 20px;
  }
  .operating-activities .title-btn,.international-trade .title-btn,.service-outsourcing .title-btn {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .col-item {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 14px;
    color: #8D8D8D;
  }
  .col-item .img {
    margin-left: 40px;
    width: 20px;
    height: 20px;
  }
  .operating-activities .item-edit {
    display: flex;
    flex-direction: column;
  }
  .operating-activities .item-edit .item-edit-item {
    height: 34px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .operating-activities .item-edit .item-edit-item .item-title{
    width: 86px;
    font-size: 14px;
    color: #8D8D8D;
  }
  .operating-activities .item-edit .item-edit-item .item-checkbox{
    margin-left: 45px;
    font-size: 14px;
    color: #000000;
  }
  .data-area {
    margin-top: 12px;
  }
  .data-area .data-item{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
    color: #8D8D8D;
    height: 24px;
  }
  .data-area .data-item-show{
    margin-left: 40px;
  }
  .data-area .data-item-edit{
    margin-left: 40px;
  }
  .data-area /deep/.el-input__inner{
    height: 24px;
  }
</style>
