<template>
  <div class="perfect">
<!--    <div class="progress">-->
<!--      <span>完善度：</span>-->
<!--      <progress-bar :value="value" />-->
<!--      <span style="margin-left: 17px;font-size: 20px;color: #156ED0;">{{ value }}%</span>-->
<!--    </div>-->
    <!-- <el-card class="perfect-progress-box flex"> -->
    <!-- <div class="perfect-progress-box">
        <div class="perfect-progress flex start">
          <span>完善度：</span>
          <el-progress :percentage="30" :stroke-width='10'></el-progress>
        </div>
    </div> -->
    <!-- </el-card> -->
    <div v-if="isBind">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" lazy>
          <info />
        </el-tab-pane>
        <el-tab-pane label="经营活动" lazy>
          <activity />
        </el-tab-pane>
        <el-tab-pane label="财务状况" lazy>
          <finance />
        </el-tab-pane>
        <el-tab-pane label="人员状况" lazy>
          <person-status />
        </el-tab-pane>
        <el-tab-pane label="知识产权" lazy>
          <knowledge />
        </el-tab-pane>
        <el-tab-pane label="荣誉资质" lazy>
          <honor />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div v-else style="margin-bottom: 50px">
      <div class="hasBindEntity">
        <div>
          <img src="../../../assets/pc/images/push/subject.png" alt="" />
        </div>
        <div style="margin-top: 10px" class="information_color">
          请先绑定主体
        </div>
        <div style="margin: 10px 0" class="information_color">
          以便查询更多政策资讯
        </div>
        <div class="button" style="text-align: center; margin-top: 30px">
          <button class="goto-bind" @click="dialogVisible = true">
            立即绑定
          </button>
        </div>
      </div>
    </div>
    <el-dialog
    title="绑定主体"
    :visible.sync="dialogVisible"
    width="500px">
    <el-form ref="form" :model="form" class="form" :rules="rule" label-position="top">
        <el-form-item prop="name" label="主体名称">
          <el-autocomplete
                class="inline-input"
                v-model="form.name"
                :fetch-suggestions="querySearch"
                placeholder="请输入机构执照名称"
                :trigger-on-focus="false"
                @select="handleSelect"
                :debounce="0"
                style="width:100%"
                clearable
              >
            </el-autocomplete>
        </el-form-item>
        <el-form-item :label="form.label" v-if="form.type == '0'">
          <div class="creditCode">
            <div style="flex:1">{{form.code}}</div>
            <div class="div-code">
              <el-input v-model="form.first_code" maxlength="1" ref="inputRef1"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.second_code"  maxlength="1" ref="inputRef2"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.third_code"  maxlength="1" ref="inputRef3"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.fourth_code"  maxlength="1" ref="inputRef4"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="form.label" v-else>
          <el-input v-model="form.codeInput" placeholder="输入18位统一社会信用代码" maxlength="18"></el-input>
        </el-form-item>
      </el-form>
    <div class="bind-btn-box">
      <button class="bind-btn" @click="bindEntity">立即绑定</button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
// import progressBar from './components/progressBar'
import info from './components/info';
import activity from './components/activity';
import Finance from './components/finance';
import PersonStatus from './components/personStatus';
import Knowledge from './components/knowledge';
import Honor from './components/honor';
// import hasBindEntityNormal from '@/components/pc/hasBindEntityNormal.vue'
import { getEntityId, getMemberId } from '@/assets/public/utils/token';
// import { getMemberId } from '@/assets/public/utils/token'
import { request } from '../../../network/index';
export default {
  components: {
    Honor,
    Knowledge,
    PersonStatus,
    Finance,
    // progressBar,
    info,
    activity
    // hasBindEntityNormal
  },
  data () {
    return {
      value: 40,
      activeName: '0',
      hasBindEntity: true,
      dialogVisible: false,
      form: {
        name: '',
        code: '',//信用编码前14位
        first_code: '',
        second_code: '',
        third_code: '',
        fourth_code: '',
        label: '统一社会信用代码',
        type: '1',
        codeInput: '',//自己输入信用编码
        creditCode: '',//天眼信用编码
        area: '',
        estiblishTime: '',
        regCapital: '',
        legalPersonName: ''
      },
      rule: {
        name: [
          { required: true, message: '请输入机构执照全称', trigger: 'blur' }
        ]
      },
      restaurants: [],
      userData: {},
      isBind: localStorage.getItem('mainName')
    };
  },
  computed: {
  },
  created () {
    this.getAccountInformation();
  },
  watch: {
    'form.name' (val) {
      if (!val) {
        this.form.code = '';
        this.form.codeInput = '';
        this.form.creditCode = '';
        this.form.type = '1';
        this.form.label = '统一社会信用代码';
      }
    },
    'form.first_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    'form.second_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${3}`].focus();
      } else {
        this.$refs[`inputRef${1}`].focus();
      }
    },
    'form.third_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${4}`].focus();
      } else {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    'form.fourth_code' (val) {
      if (val === '') {
        this.$refs[`inputRef${3}`].focus();
      }
    }
  },
  mounted() {
    const id = JSON.parse(localStorage.getItem('userinfo')).entityId;
    const id2 = JSON.parse(sessionStorage.getItem('userinfo'));
    if (id === 0) {
      if (id2) {
        this.hasBindEntity = true;
      } else {
        this.hasBindEntity = false;
      }
    } else {
      this.hasBindEntity = true;
    }
  },
  methods: {
    getEntity () {
      return getEntityId();
    },
    testCode (rule, value, callback) {
      if (value.length !== 18) {
        callback(new Error('输入18位统一社会信用代码'));
      } else {
        callback();
      }
    },
    async bindMember (area, estiblishTime, regCapital, legalPersonName, entityName, code) {
      const { data: res } = await request({
        method: 'post',
        url: '/pcp/PersonalCenter/newbindmemberone',
        data: {
          area: area,
          estiblishTime: estiblishTime,
          regCapital: regCapital,
          legalPersonName: legalPersonName,
          memberId: getMemberId(),
          entityName: entityName,
          code: code,
          sysType: 1
        }
      });
      if (res.code !== 200) { return this.$message.error('主体名称或信用代码不正确'); }
      // this.hasBindEntity = true
      this.dialogVisible = !this.dialogVisible;
      this.$message.success('绑定成功');
      const str = JSON.parse(localStorage.getItem('userinfo'));
      str.entityId = res.data.entityId;
      const str2 = JSON.stringify(str);
      localStorage.setItem('userinfo', str2);
      sessionStorage.setItem('userinfo', str2);
      this.getAccountInformation();
    },
    bindEntity () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.type === '0') {
            if (this.form.first_code === this.form.creditCode[14] && this.form.second_code === this.form.creditCode[15] && this.form.third_code === this.form.creditCode[16] && this.form.fourth_code === this.form.creditCode[17]) {
              this.bindMember(this.form.area, this.form.estiblishTime, this.form.regCapital, this.form.legalPersonName, this.form.name, this.form.creditCode);
            } else {
              this.$message.error('请补全后四位');
            }
          } else {
            if (this.form.type === '1' && this.form.codeInput && this.form.codeInput.length === 18) {
              this.bindMember(this.form.area, this.form.estiblishTime, this.form.regCapital, this.form.legalPersonName, this.form.name, this.form.codeInput);
            } else {
              this.$message.error('请输入18位社会信用代码');
            }
          }
        }
      });
    },
    querySearch (queryString, cb) {
      request({
        method: 'GET',
        url: '/pcp/PersonalCenter/getentitynameandcode',
        params: {
          entityName: queryString
        }
      }).then(res => {
        if (res.data.code === 200) {
          res.data.data.items.forEach(v => {
            v.value = v.name;
          });
          let results = [];
          res.data.data.items.map(item => {
            // results.push(Object.assign({},{value:item.name}))
            results.push(item);
          });
          if (results.length === 0) {
            results = [ { value: '暂无数据' } ];
          }
          cb(results);
        }
      }).catch(err => { console.log(err); });
    },
    handleSelect (item) {
      this.form.code = item.creditCode.slice(0, 6) + '******';
      this.form.label = '请补全统一社会信用代码';
      this.form.type = '0';
      // for (let i in item) {
      //   if (this.form[i]) this.form[i] = item[i];
      // }
      this.form.creditCode = item.creditCode;
      this.form.area = item.base;
      this.form.estiblishTime = item.estiblishTime;
      this.form.regCapital = item.regCapital;
      this.form.legalPersonName = item.legalPersonName;
      this.form.name = item.name;
    },
    async getAccountInformation () {
      if (getMemberId() !== '') {
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/PersonalCenter/accountInformation',
          params: {
            id: getMemberId()
          }
        });
        if (res.code !== 200) return this.$message.error('信息获取失败');
        if (res.data.length > 0) {
          this.userData = res.data[0];
          localStorage.setItem('mainName', JSON.stringify(res.data[0].name));
          this.isBind = res.data[0].name
        } else {
          this.userData = {};
        }
        return Promise.resolve();
      }
    }
  }
};
</script>

<style lang="less" scoped>
  .perfect {
    .perfect-progress-box{
      width: 100%;
      height: 50px;
      margin-bottom: 20px;
      background-color: #ffffff;
      box-shadow: 0 0 5px 0 #ebebeb;
      .perfect-progress{
        box-sizing: border-box;
        height: 100%;
        padding: 10px 30px;
        span{
          font-size: 14px;
          color: #000000a2;
          margin-right: 10px;
        }
        .el-progress{
          width: 500px;
         /deep/ .el-progress__text{
            margin-left: 20px;
            color: #409EFF;
          }
        }
      }
    }
    .progress {
      display: flex;
      font-size: 15px;
      height: 60px;
      padding-left: 30px;
      margin-bottom: 12px;
      box-shadow: 0 0 5px 0 #ebebeb;
      & > span,
      & > div {
        align-self: center;
      }
    }
    .el-tabs {
      box-shadow: 0 0 5px 0 #ebebeb;
      /deep/.el-tabs__header,
      /deep/.el-tabs__nav-wrap,
      /deep/.el-tabs__nav-scroll,
      /deep/.el-tabs__item {
        font-size: 15px;
        height: 50px;
        line-height: 50px;
        color: #585858;
      }
      /deep/.el-tabs__item + div {
        padding: 0 15px;
      }
      /deep/.el-tabs__nav-wrap {
        padding-left: 30px;
      }
      /deep/.el-tabs__nav-wrap::after {
        height: 1px;
      }
      /deep/.el-tabs__active-bar {
        height: 3px;
      }
      /deep/.is-active {
        color: #000000;
        font-weight: bold;
      }
      .el-tab-pane {
        padding: 14px 30px;
      }
    }
  }
  .bind-area {
    margin-top: 60px;
    margin-bottom: 100px;
  }
  .goto-bind {
    margin-right: 10px;
    width: 120px;
    height: 42px;
    border: 1px solid #156ED0;
    border-radius: 5px;
    cursor: pointer;
    background: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #156ED0;
  }
  /deep/ .el-tabs__active-bar{
    background-color: #156ED0;
  }
  .bind-btn-box {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .bind-btn {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    border: none;
    width: 320px;
    height: 46px;
    background: #156ED0;
    border-radius: 5px;
    cursor: pointer;
  }
  /deep/.el-input__inner {
    font-size: 16px;
    // width:150%;
    height: 50px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  /deep/.el-form-item{
    width: 60%;
  }
  .text{
    text-align: center;
  }
  .creditCode{
    display: flex;
    .div-code{
        margin-right: 5px;
        text-align:center;
        /deep/.el-input__inner{
          width: 40px;
          height: 30px;
          font-size: 12px;
        }
    }
  }
  .hasBindEntity {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
