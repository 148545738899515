<template>
  <div class="finance">
    <el-alert title="（以下所填信息，为上一年度数据）" type="info" show-icon :closable="false"></el-alert>
    <div class="finance-status">
      <!--  财务状况区域-->
      <div class="title-btn">
        <div style="color: #156ED0;font-weight: bold;flex: 1;">财务状况</div>
        <div>
          <el-button v-if="statusFlag" plain size="mini" @click="statusFlag = false">编辑</el-button>
          <div v-else>
            <el-button plain type="primary" size="mini" @click="saveStatus">保存</el-button>
            <el-button plain size="mini" @click="closeFin1">取消</el-button>
          </div>
        </div>
      </div>
      <div class="data-area">
        <!--          数据区域-->
        <div class="data-item" v-for="(item1,index1) in tagsName.financialSituation" :key="item1.id">
          <span style="width: 90px">{{ item1.title }}</span>
          <div class="data-item-show" v-if="statusFlag">{{ tagsValue.financialSituation[index1] }} <span v-if="index1 === 7">%</span><span v-else>万元</span></div>
          <div class="data-item-edit" v-else><el-input v-model="financialSituationData[index1]" style="width: auto;" maxlength="9" oninput="value=value.replace(/^0|[^\d]/g, '')" />&nbsp;<span v-if="index1 === 7">%</span><span v-else>万元</span></div>
        </div>
      </div>
    </div>
    <div class="finance-revenue">
      <!--  营收区域-->
      <div class="title-btn">
        <div style="color: #156ED0;font-weight: bold;flex: 1;">营收</div>
        <div>
          <el-button v-if="revenueFlag" plain size="mini" @click="revenueFlag = false">编辑</el-button>
          <div v-else>
            <el-button plain type="primary" size="mini" @click="saveRevenue">保存</el-button>
            <el-button plain size="mini" @click="closeFin2">取消</el-button>
          </div>
        </div>
      </div>
      <div class="data-area">
        <!--   研发区域-->
        <div class="data-item" v-for="(item1,index1) in tagsName.revenue" :key="item1.id">
          <span style="width: 90px">{{ item1.title }}</span>
          <div class="data-item-show" v-if="revenueFlag">{{ tagsValue.revenue[index1] }} 万元</div>
          <div class="data-item-edit" v-else><el-input v-model="revenueData[index1]" style="width: auto;" maxlength="9" oninput="value=value.replace(/^0|[^\d]/g, '')" />&nbsp;万元</div>
        </div>
      </div>
    </div>
    <div class="finance-research">
      <!--  财务状况区域-->
      <div class="title-btn">
        <div style="color: #156ED0;font-weight: bold;flex: 1;">研发</div>
        <div>
          <el-button v-if="researchFlag" plain size="mini" @click="researchFlag = false">编辑</el-button>
          <div v-else>
            <el-button plain type="primary" size="mini" @click="saveResearch">保存</el-button>
            <el-button plain size="mini" @click="closeFin3">取消</el-button>
          </div>
        </div>
      </div>
      <div class="data-area">
        <!--          数据区域-->
        <div class="data-item" v-for="(item1,index1) in tagsName.research" :key="item1.id">
          <span style="width: 90px">{{ item1.title }}</span>
          <div class="data-item-show" v-if="researchFlag">{{ tagsValue.research[index1] }} 万元</div>
          <div class="data-item-edit" v-else><el-input v-model="researchData[index1]" style="width: auto;" maxlength="9" oninput="value=value.replace(/^0|[^\d]/g, '')" />&nbsp;万元</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../network';
import { getEntityId } from '@/assets/public/utils/token';
export default {
  name: 'finance',
  data () {
    return {
      tagsName: {
        financialSituation: [],
        revenue: [],
        research: []
      },
      tagsValue: {
        financialSituation: [],
        revenue: [],
        research: []
      },
      financialSituationData: [ '', '', '', '', '', '', '', '' ],
      revenueData: [ '' ],
      researchData: [ '' ],
      statusFlag: true,
      revenueFlag: true,
      researchFlag: true
    };
  },
  mounted () {
    this.findFinancialSituationName().then(() => this.findFinancialSituationValue());
  },
  methods: {
    async findFinancialSituationName () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findFinancialSituation'
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsName = res.data;
      return Promise.resolve();
    },
    async findFinancialSituationValue () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findFinancialSituation',
        params: {
          // entityId: getEntityId()
          entityId: entityId
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsValue = res.data;
      this.financialSituationData = this.tagsValue.financialSituation;
      this.revenueData = this.tagsValue.revenue;
      this.researchData = this.tagsValue.research;
      return Promise.resolve();
    },
    async saveStatus () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateFinancialSituation',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          totalAssets: this.financialSituationData[0],
          netAssets: this.financialSituationData[1],
          netProfit: this.financialSituationData[2],
          fixedAssets: this.financialSituationData[3],
          salesRevenue: this.financialSituationData[4],
          mainIncome: this.financialSituationData[5],
          totalTaxPayment: this.financialSituationData[6],
          assetLiabilityRatio: this.financialSituationData[7]
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.statusFlag = true;
      this.findFinancialSituationValue();
    },
    async saveRevenue () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateBusinessIncome',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          businessIncome: this.revenueData[0]
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.revenueFlag = true;
      this.findFinancialSituationValue();
    },
    async saveResearch () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateBusinessIncome',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          rdExpenses: this.researchData[0]
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.researchFlag = true;
      this.findFinancialSituationValue();
    },
    closeFin1 () {
      this.findFinancialSituationName().then(() => {
        this.statusFlag = true;
        this.findFinancialSituationValue();
      }
      );
    },
    closeFin2 () {
      this.findFinancialSituationName().then(() => {
        this.revenueFlag = true;
        this.findFinancialSituationValue();
      });
    },
    closeFin3 () {
      this.findFinancialSituationName().then(() => {
        this.researchFlag = true;
        this.findFinancialSituationValue();
      }
      );
    }
  }
};
</script>

<style scoped>
.finance-status, .finance-revenue, .finance-research {
  padding-bottom: 30px;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 20px;
}
.finance-status .title-btn,.finance-revenue .title-btn,.finance-research .title-btn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.data-area {
  margin-top: 12px;
}
.data-area .data-item{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #8D8D8D;
  height: 24px;
}
.data-area .data-item-show{
  margin-left: 40px;
}
.data-area .data-item-edit{
  margin-left: 40px;
}
.data-area /deep/.el-input__inner{
  height: 24px;
}
</style>
